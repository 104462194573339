import ApiService from "@/core/services/api.service.js";

const contactService = {
    getContacts() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/contact/requests")
            .then(function(response) {
              if (response.data) {
                resolve(response.data[0]);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    getContactById(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/contact/requests/detail/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    updateContact(data) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/contact/requests/reply/"+data.id,data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },

}

export default contactService;